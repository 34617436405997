<template>
  <v-container class="my-0 my-md-16 py-0  px-0 px-md-3 pb-md-16">
    <v-row class="no-gutters">
      <v-col
        xl="6"
        offset-xl="3"
        md="8"
        offset-md="2"
        sm="12"
        offset-sm="0"
        class="py-0"
      >
        <v-card class="elevation-24" style="border-radius:6px">
          <v-toolbar
            flat
            color="primary"
            height="120px"
            dark
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'rounded-t-0'
                : 'rounded-t-lg pl-3'
            "
          >
            <v-toolbar-title class="d-flex align-center">
              <v-icon large>
                mdil-account
              </v-icon>
              <span class="pl-6 text-h4">{{ $t("profil") }}</span>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-8">
            <v-row>
              <v-col cols="12">
                <v-card outlined>
                  <v-card-text>
                    <v-text-field
                      v-model="email"
                      :label="$t('eposta')"
                      disabled
                    />
                    <v-text-field
                      v-model="profil.website"
                      :label="$t('website')"
                    />

                    <v-text-field
                      v-model="profil.sirket"
                      :label="$t('sirketAd')"
                    />
                    <v-text-field
                      v-model="profil.kisaTanim"
                      :label="$t('kisaTanim')"
                    />
                    <v-text-field v-model="profil.title" :label="$t('title')" />
                    <v-text-field v-model="profil.tel" :label="$t('telefon')" />
                    <v-textarea
                      v-model="profil.adres"
                      auto-grow
                      rows="1"
                      :label="$t('adres')"
                    />
                    <v-switch
                      v-model="profil.menuDense"
                      :label="$t('menuDense')"
                    ></v-switch>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <template>
                  <v-card outlined>
                    <v-card-title>
                      {{ $t("logo") }}
                    </v-card-title>
                    <v-card-text>
                      <v-file-input
                        v-if="typeof profil.logo != 'string'"
                        v-model="profil.logo"
                        :label="$t('gorselSec')"
                        hide-details
                        append-icon="mdi-camera"
                        prepend-icon=""
                        accept="image/png, image/jpeg, image/jpg"
                      />
                      <v-img
                        v-if="typeof profil.logo == 'string'"
                        max-width="150"
                        :src="profil.logo"
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        v-if="typeof profil.logo != 'string'"
                        depressed
                        color="secondary"
                        class="text-capitalize"
                        @click="gorselYukle('logo')"
                      >
                        {{ $t("yukle") }}
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        v-if="typeof profil.logo == 'string'"
                        depressed
                        color="error"
                        class="text-capitalize"
                        @click="gorselSil('logo')"
                      >
                        {{ $t("sil") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-col>
              <v-col cols="12">
                <template>
                  <v-card outlined>
                    <v-card-title>
                      {{ $t("logoKategori") }}
                    </v-card-title>
                    <v-card-text>
                      <v-file-input
                        v-if="typeof profil.logoKategori != 'string'"
                        v-model="profil.logoKategori"
                        :label="$t('gorselSec')"
                        hide-details
                        append-icon="mdi-camera"
                        prepend-icon=""
                        accept="image/png, image/jpeg, image/jpg"
                      />
                      <v-img
                        v-if="typeof profil.logoKategori == 'string'"
                        max-width="150"
                        :src="profil.logoKategori"
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        v-if="typeof profil.logoKategori != 'string'"
                        depressed
                        color="secondary"
                        class="text-capitalize"
                        @click="gorselYukle('logoKategori')"
                      >
                        {{ $t("yukle") }}
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        v-if="typeof profil.logoKategori == 'string'"
                        depressed
                        color="error"
                        class="text-capitalize"
                        @click="gorselSil('logoKategori')"
                      >
                        {{ $t("sil") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-col>
              <v-col cols="12">
                <template>
                  <v-card outlined>
                    <v-card-title>
                      {{ $t("logoMobil") }}
                    </v-card-title>
                    <v-card-text>
                      <v-file-input
                        v-if="typeof profil.logoMobil != 'string'"
                        v-model="profil.logoMobil"
                        :label="$t('gorselSec')"
                        hide-details
                        append-icon="mdi-camera"
                        prepend-icon=""
                        accept="image/png, image/jpeg, image/jpg"
                      />
                      <v-img
                        v-if="typeof profil.logoMobil == 'string'"
                        max-width="150"
                        :src="profil.logoMobil"
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        v-if="typeof profil.logoMobil != 'string'"
                        depressed
                        color="secondary"
                        class="text-capitalize"
                        @click="gorselYukle('logoMobil')"
                      >
                        {{ $t("yukle") }}
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        v-if="typeof profil.logoMobil == 'string'"
                        depressed
                        color="error"
                        class="text-capitalize"
                        @click="gorselSil('logoMobil')"
                      >
                        {{ $t("sil") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-col>
              <v-col cols="12">
                <template>
                  <v-card outlined>
                    <v-card-title>
                      {{ $t("logoFooter") }}
                    </v-card-title>
                    <v-card-text>
                      <v-file-input
                        v-if="typeof profil.logoFooter != 'string'"
                        v-model="profil.logoFooter"
                        :label="$t('gorselSec')"
                        hide-details
                        append-icon="mdi-camera"
                        prepend-icon=""
                        accept="image/png, image/jpeg, image/jpg"
                      />
                      <v-img
                        v-if="typeof profil.logoFooter == 'string'"
                        max-width="150"
                        :src="profil.logoFooter"
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        v-if="typeof profil.logoFooter != 'string'"
                        depressed
                        color="secondary"
                        class="text-capitalize"
                        @click="gorselYukle('logoFooter')"
                      >
                        {{ $t("yukle") }}
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        v-if="typeof profil.logoFooter == 'string'"
                        depressed
                        color="error"
                        class="text-capitalize"
                        @click="gorselSil('logoFooter')"
                      >
                        {{ $t("sil") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-col>
              <v-col cols="12">
                <template>
                  <v-card outlined>
                    <v-card-title>
                      {{ $t("kapak") }}
                    </v-card-title>
                    <v-card-text>
                      <v-file-input
                        v-if="typeof profil.kapak != 'string'"
                        v-model="profil.kapak"
                        :label="$t('gorselSec')"
                        hide-details
                        append-icon="mdi-camera"
                        prepend-icon=""
                        accept="image/png, image/jpeg, image/jpg"
                      />
                      <v-img
                        v-if="typeof profil.kapak == 'string'"
                        :src="profil.kapak"
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        v-if="typeof profil.kapak != 'string'"
                        depressed
                        color="secondary"
                        class="text-capitalize"
                        @click="gorselYukle('kapak')"
                      >
                        {{ $t("yukle") }}
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        v-if="typeof profil.kapak == 'string'"
                        depressed
                        color="error"
                        class="text-capitalize"
                        @click="gorselSil('kapak')"
                      >
                        {{ $t("sil") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-col>
              <v-col cols="12" md="4">
                <template>
                  <v-card outlined>
                    <v-card-title>{{ $t("primary") }}</v-card-title>
                    <v-card-text>
                      <v-color-picker
                        v-model="renkler.primary"
                        dot-size="10"
                        hide-mode-switch
                        mode="hexa"
                        swatches-max-height="214"
                      ></v-color-picker>
                    </v-card-text>
                  </v-card>
                </template>
              </v-col>
              <v-col cols="12" md="4">
                <template>
                  <v-card outlined>
                    <v-card-title>{{ $t("secondary") }}</v-card-title>
                    <v-card-text>
                      <v-color-picker
                        v-model="renkler.secondary"
                        dot-size="10"
                        hide-mode-switch
                        mode="hexa"
                        swatches-max-height="214"
                      ></v-color-picker>
                    </v-card-text>
                  </v-card>
                </template>
              </v-col>
              <v-col cols="12" md="4">
                <template>
                  <v-card outlined>
                    <v-card-title>{{ $t("tertiary") }}</v-card-title>
                    <v-card-text>
                      <v-color-picker
                        v-model="renkler.tertiary"
                        dot-size="10"
                        hide-mode-switch
                        mode="hexa"
                        swatches-max-height="214"
                      ></v-color-picker>
                    </v-card-text>
                  </v-card>
                </template>
              </v-col>
              <v-col cols="12" md="4">
                <template>
                  <v-card outlined>
                    <v-card-title>{{ $t("background") }}</v-card-title>
                    <v-card-text>
                      <v-color-picker
                        v-model="renkler.arkaplan"
                        dot-size="10"
                        hide-mode-switch
                        mode="hexa"
                        swatches-max-height="214"
                      ></v-color-picker>
                    </v-card-text>
                  </v-card>
                </template>
              </v-col>
              <v-col cols="12" md="4">
                <template>
                  <v-card outlined>
                    <v-card-title>{{ $t("info") }}</v-card-title>
                    <v-card-text>
                      <v-color-picker
                        v-model="renkler.info"
                        dot-size="10"
                        hide-mode-switch
                        mode="hexa"
                        swatches-max-height="214"
                      ></v-color-picker>
                    </v-card-text>
                  </v-card>
                </template>
              </v-col>
              <v-col cols="12" md="4">
                <template>
                  <v-card outlined>
                    <v-card-title>{{ $t("warning") }}</v-card-title>
                    <v-card-text>
                      <v-color-picker
                        v-model="renkler.error"
                        dot-size="10"
                        hide-mode-switch
                        mode="hexa"
                        swatches-max-height="214"
                      ></v-color-picker>
                    </v-card-text>
                  </v-card>
                </template>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="pa-8">
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="d-flex align-center">
                <v-text-field
                  v-model="sifre"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  :label="$t('yeniSifre')"
                  counter
                  :rules="gerekli"
                  required
                  hide-details
                  @click:append="show = !show"
                />
                <v-btn
                  class="px-6 mx-6"
                  text
                  color="primary"
                  @click="sifreDegistir()"
                >
                  {{ $t("sifreDegistir") }}
                </v-btn>
              </div>
            </v-form>
            <v-spacer />
            <v-btn large color="primary" depressed @click="save()">
              {{ $t("kaydet") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      multi-line
      timeout="2000"
      absolute
      centered
      color="accent"
      elevation="24"
      rounded="pill"
    >
      <div class="text-center">
        {{ snackbarText }}
      </div>
    </v-snackbar>
    <v-overlay :value="snackbar" :z-index="0" />

    <v-dialog v-model="dialog" max-width="400px">
      <v-card color="info">
        <v-app-bar dark flat prominent color="info">
          <v-toolbar-title class="pa-2">
            {{ $t("dikkat") }}
          </v-toolbar-title>

          <v-spacer />

          <v-btn icon class="ma-0 mt-3" @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text>
          <div class="white-text">
            {{ dialogText }}
          </div>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-8">
          <v-spacer />
          <v-btn color="accent" @click="logout()">
            {{ $t("cikisYap") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { veri, giris, depo } from "./../db";
import Compressor from "compressorjs";
export default {
  name: "ProfilView",
  data: () => ({
    sifre: "",
    email: "",
    profil: {
      adres: "",
      kisaTanim: "",
      sirket: "",
      tel: "",
      logo: "",
      kapak: ""
    },
    renkler: {},
    dialog: false,
    dialogText: "",
    snackbar: false,
    snackbarText: "",
    show: false,
    valid: true,
    gerekli: [
      v => !!v || "Şifre girin",
      v => (v && v.length >= 6) || "En az 6 karakter girmeniz gerekli"
    ],
    rules: {
      required: value => !!value || "Required.",
      min: v => v.length >= 6 || "Min 6 characters",
      emailMatch: () => "The email and password you entered don't match"
    }
  }),
  firebase: {
    profil: veri.ref("profil"),
    renkler: veri.ref("renkler")
  },
  computed: {},

  watch: {},
  created() {
    var user = giris.currentUser;

    if (user != null) {
      this.email = user.email;
      // photoUrl = user.photoURL;
      // emailVerified = user.emailVerified;
      // uid = user.uid;
    }
  },

  methods: {
    gorselYukle(yer) {
      const file = this.profil[yer];

      if (!file) {
        return;
      }
      var self = this;
      new Compressor(file, {
        quality: 0.8,

        success(result) {
          var uploadTask = depo
            .ref()
            .child("profil/" + result.name)
            .put(result);
          uploadTask.on(
            "state_changed",
            sp => {},
            null,
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                self.profil[yer] = downloadURL;

                veri
                  .ref("profil/")
                  .set(self.profil)
                  .then(() => {
                    self.snackbar = true;
                    self.snackbarText = yer + " yüklendi";
                  });
              });
            }
          );
        }
      });
    },

    gorselSil(yer) {
      confirm(this.$t("silOnay")) && this.gorselSilOnay(yer);
    },
    gorselSilOnay(yer) {
      var fileUrl = this.profil[yer];
      var fileRef = depo.refFromURL(fileUrl);
      fileRef.delete().then(() => {
        veri
          .ref("profil/" + yer)
          .set(null)
          .then(() => {
            this.snackbar = true;
            this.snackbarText = yer + " Silindi";
          });
      });
    },
    logout() {
      giris.signOut().then(() => {
        this.$router.replace("/login");
      });
    },
    sifreDegistir() {
      if (this.$refs.form.validate()) {
        var user = giris.currentUser;
        var newPassword = this.sifre;

        user
          .updatePassword(newPassword)
          .then(() => {
            this.snackbar = true;
            this.snackbarText = this.$t("sifreGuncel");
          })
          .catch(error => {
            this.dialog = true;
            this.dialogText = error.message;
          });
      } else {
      }
    },

    save() {
      veri
        .ref("profil/")
        .set(this.profil)
        .then(() => {
          veri
            .ref("renkler/")
            .set(this.renkler)
            .then(() => {
              this.snackbar = true;
              this.snackbarText = this.$t("veriBasarili");
            });
        });
    }
  }
};
</script>
<style scoped></style>
